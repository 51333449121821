import {
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
} from "@mui/material";
import { Button } from "@cfa/react-components";
import React, { useState } from "react";
import styles from "./SaveActionDialog.module.scss";

import { getFullName } from "../../../../utils/helpers/PersonHelper";
import { TravelValidationConf as TravelValidationConfiguration } from "@cfacorp/business-travel-api-client";
import { useUpdateUserConfiguration } from "../../../../hooks/api/delegates";
import { Person } from "@cfacorp/business-travel-api-client";

interface DelegateSaveActionDialogProp {
  selectedDelegates: Person[];
  currentDelegates: Person[];
  isUpdatable: boolean;
  savedConfiguration?: TravelValidationConfiguration;
  refreshView: (config: TravelValidationConfiguration) => void;
  revertView: () => void;
  handleFailSave: () => void;
}

const SaveActionDialog: React.FC<DelegateSaveActionDialogProp> = (
  DelegateSaveActionDialog: DelegateSaveActionDialogProp,
): JSX.Element => {
  const [open, setOpen] = useState(false);
  const { mutate: updateUserConfiguration } = useUpdateUserConfiguration();

  let currentName: string = getFullName(
    DelegateSaveActionDialog.currentDelegates[0],
  );
  let selectedName: string = getFullName(
    DelegateSaveActionDialog.selectedDelegates[0],
  );

  let isRemoveCurrentDelegate: boolean =
    DelegateSaveActionDialog.currentDelegates.length === 1 &&
    DelegateSaveActionDialog.selectedDelegates.length === 0;

  const handleClickOpen = () => {
    determineSaveText();
    setOpen(true);
  };

  const handleFailClose = (): void => {
    DelegateSaveActionDialog.handleFailSave();
    setOpen(false);
  };

  const handleDisagreeClose = () => {
    DelegateSaveActionDialog.revertView();
    setOpen(false);
  };

  const handleAgreeClose = (config: TravelValidationConfiguration) => {
    DelegateSaveActionDialog.refreshView(config);
    setOpen(false);
  };

  /*
        There are two scenarios, in conditional order, that occur for updating a user's delegate(s)
        1) Remove my current delegate so that I no longer have an assigned delegate.
        3) Assign a delegate or change selected delegate.
     */
  const updateDelegateConfig = () => {
    if (!DelegateSaveActionDialog.savedConfiguration) {
      handleFailClose();
      return;
    }

    // make a shallow copy
    let newConfig = { ...DelegateSaveActionDialog.savedConfiguration };

    if (isRemoveCurrentDelegate) {
      newConfig.delegateCfaId = "";
      newConfig.delegatePersonId = "";
    } else {
      newConfig.delegateCfaId =
        DelegateSaveActionDialog.selectedDelegates[0].cfaId;
      newConfig.delegatePersonId =
        DelegateSaveActionDialog.selectedDelegates[0].personId;
    }

    updateUserConfiguration(newConfig, {
      onSuccess: () => handleAgreeClose(newConfig),
      onError: handleFailClose,
    });
  };

  const determineSaveTitle = () => {
    if (isRemoveCurrentDelegate) {
      return "Are you sure that you want to remove the following delegate?";
    } else if (DelegateSaveActionDialog.currentDelegates.length === 1) {
      return "Are you sure that you want to change to the following delegate?";
    } else {
      return "Are you sure that you want to select the following delegate?";
    }
  };

  const determineSaveText = () => {
    if (isRemoveCurrentDelegate) {
      return currentName;
    } else if (DelegateSaveActionDialog.currentDelegates.length === 1) {
      return currentName + " -> " + selectedName;
    } else {
      return selectedName;
    }
  };

  return (
    <div className={styles.container}>
      <Button
        color="primary"
        onClick={handleClickOpen}
        data-test="save-delegate-button"
      >
        Save
      </Button>
      <Dialog
        open={open}
        onClose={() => {
          setOpen(false);
        }}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
      >
        <div className={styles.dialogContainer}>
          <DialogTitle id="alert-dialog-title">
            {determineSaveTitle()}
          </DialogTitle>
          <DialogContent>
            <DialogContentText id="alert-dialog-description">
              {determineSaveText()}
            </DialogContentText>
          </DialogContent>
          <DialogActions className={styles.dialogContainer}>
            <Button
              variant="outlined"
              onClick={handleDisagreeClose}
              className="acceptButton"
              data-test="disagree"
            >
              Cancel
            </Button>
            <Button onClick={updateDelegateConfig} autoFocus data-test="agree">
              Agree
            </Button>
          </DialogActions>
        </div>
      </Dialog>
    </div>
  );
};

export default SaveActionDialog;
