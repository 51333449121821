import React, { useState } from "react";
import { BannerMessage, BannerMessageSeverity } from "@cfa/react-components";
import styles from "./Toaster.module.scss";
import { useGetBannerMessages } from "../../hooks/api/admin";

const Toaster: React.FC = () => {
  const { data: messages } = useGetBannerMessages();

  const [dismissed, setDismissed] = useState({});

  const handleClose = (messageId: string) =>
    setDismissed((prev) => ({ ...prev, [messageId]: true }));

  return (
    <div className={styles.toaster}>
      {messages?.map((message) => {
        if (!message.messageId) return null;
        const isVisible = !(message?.messageId in dismissed);
        return (
          <BannerMessage
            key={message.messageId}
            severity={message.severity.toLowerCase() as BannerMessageSeverity}
            show={isVisible}
            onClose={() => handleClose(message.messageId || "")}
          >
            {message.message}
          </BannerMessage>
        );
      })}
    </div>
  );
};

export default Toaster;
