import React, { useEffect, useState } from "react";
import { Grid } from "@mui/material";
import { useHistory, useLocation } from "react-router-dom";
import "react-datepicker/dist/react-datepicker.css";
import I8ln from "../../i18n/utils";
import { TabItem, TabPanel, Tabs } from "@cfa/react-components";
import "./Admin.scss";
import { isAdmin } from "../../utils/helpers/AuthHelper";
import BannerTab from "./components/BannerTab/BannerTab";

const Admin: React.FC = (): JSX.Element => {
  const history = useHistory();
  const location = useLocation();

  useEffect(() => {
    // nav to home if not admin
    if (!isAdmin()) {
      history.replace("/");
    }
  }, [location, history]);

  return (
    <div style={{ padding: "1em" }}>
      <BannerTab />
    </div>
  );
};

export default Admin;
