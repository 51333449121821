import { useQuery } from "@tanstack/react-query";
import { businessTravelService } from "../../../utils/API";
import { TripDay } from "@cfacorp/business-travel-api-client";

const useTripsDaysQuery = (onSuccess?: (tripDays: TripDay[]) => void) => {
  const fetchTrips = async (): Promise<TripDay[]> => {
    const response = await businessTravelService.getTripDays({
      status: undefined,
    });
    if (onSuccess) {
      onSuccess(response.tripDays || []);
    }
    return response.tripDays || [];
  };

  return useQuery({
    queryKey: ["Trips"],
    queryFn: fetchTrips,
  });
};

export { useTripsDaysQuery };
