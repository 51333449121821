import React from "react";
import { Typography } from "@cfa/react-components";
import styles from "./HomeHeader.module.scss";
import { isValidationWindowOpen } from "../../utils/validationWindowHelper";
import { useDelegateContext } from "../../../../context/DelegateContext";

type HomeHeaderProps = {
  unvalidatedTripsCount: number;
  validatedTripsCount: number;
};

const HomeHeader: React.FC<HomeHeaderProps> = ({
  unvalidatedTripsCount,
  validatedTripsCount,
}) => {
  const { selectedUser, isDelegate } = useDelegateContext();

  const tripPluralText = unvalidatedTripsCount === 1 ? "trip" : "trips";

  const renderDelegateName = () => (
    <Typography variant="h2" className={styles.unvalidatedDelegateTripText}>
      {selectedUser?.firstName}
    </Typography>
  );

  const renderNoTripsMessage = () => (
    <Typography variant="h2" className={styles.titleTextContainer}>
      {isDelegate ? (
        <> {renderDelegateName()} has no trips to validate. </>
      ) : (
        "You have no trips to validate."
      )}
    </Typography>
  );

  const renderAllTripsValidatedMessage = () => (
    <Typography variant="h2" className={styles.titleTextContainer}>
      {isDelegate ? (
        <>All {renderDelegateName()} trips are validated!</>
      ) : (
        "All your trips are validated!"
      )}
    </Typography>
  );

  const renderTripsToValidateMessage = () => (
    <Typography variant="h2" className={styles.titleTextContainer}>
      {isDelegate ? (
        <>
          {renderDelegateName()} has {unvalidatedTripsCount} {tripPluralText} to
          validate.
        </>
      ) : (
        <>
          You have {unvalidatedTripsCount} {tripPluralText} to validate.
        </>
      )}
    </Typography>
  );

  const renderValidationWindowClosedMessage = () => (
    <Typography variant="h2" className={styles.titleTextContainer}>
      The validation window is closed.
    </Typography>
  );

  if (isValidationWindowOpen()) {
    if (unvalidatedTripsCount === 0 && validatedTripsCount === 0) {
      return renderNoTripsMessage();
    } else if (unvalidatedTripsCount === 0 && validatedTripsCount > 0) {
      return renderAllTripsValidatedMessage();
    } else {
      return renderTripsToValidateMessage();
    }
  }

  return renderValidationWindowClosedMessage();
};

export default HomeHeader;
