import { LoginCallback, SecureRoute, Security } from "@okta/okta-react";
import React, { ReactElement, Suspense, useState, useEffect } from "react";
import "@cfa/react-components/dist/styles.min.css";
import { getOktaAccessToken } from "./utils/LocalStorage";
import {
  BrowserRouter as Router,
  Switch,
  Route,
  useHistory,
} from "react-router-dom";
import { oktaAuthConfig } from "./config";
import Delegate from "./pages/Delegate/Delegate";
import Admin from "./pages/Admin/Admin";
import History from "./pages/History/History";
import Header from "./components/Header/Header";
import Home from "./pages/Home/Home";
import { QueryClient, QueryClientProvider } from "@tanstack/react-query";
import { OktaAuth, toRelativeUrl } from "@okta/okta-auth-js";
import { DelegateProvider } from "./context/DelegateContext";
import axios, { InternalAxiosRequestConfig } from "axios";
import TripDetails from "./pages/TripDetails/TripDetails";

const queryClient = new QueryClient();

const App: React.FC = (): JSX.Element => {
  const oktaAuth = new OktaAuth(oktaAuthConfig);
  const history = useHistory();
  const restoreOriginalUri = async (
    _oktaAuth: unknown,
    originalUri: string,
  ) => {
    history.replace(toRelativeUrl(originalUri || "/", window.location.origin));
  };

  useEffect(() => {
    axios.interceptors.request.use((config: InternalAxiosRequestConfig) => {
      if (config.headers) {
        // eslint-disable-next-line no-param-reassign
        config.headers.Authorization = `Bearer ${getOktaAccessToken()}`;
      } else {
        console.error(
          `No headers existing to place on authorization header. API calls will fail without bearer token`,
        );
        console.error(config);
      }
      return config;
    });
  }, []);

  return (
    <Suspense fallback={null}>
      <QueryClientProvider client={queryClient}>
        <DelegateProvider>
          <Security oktaAuth={oktaAuth} restoreOriginalUri={restoreOriginalUri}>
            <Header />
            <Switch>
              <Route path="/implicit/callback" component={LoginCallback} />
              <SecureRoute path="/" exact component={Home} />
              <SecureRoute path="/delegate" component={Delegate} />
              <SecureRoute path="/history" component={History} />
              <SecureRoute path="/admin" component={Admin} />
              <SecureRoute path="/trip/:tripId?" component={TripDetails} />
            </Switch>
          </Security>
        </DelegateProvider>
      </QueryClientProvider>
    </Suspense>
  );
};

export default function AppWithRouterAccess(): ReactElement {
  return (
    <Router>
      <App />
    </Router>
  );
}
