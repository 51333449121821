import { Grid } from '@mui/material';
import React from 'react';
import './errorMessage.scss';

interface ErrorMessageProp {
    message: string;
    cssClass?: string;
}

const ErrorMessage: React.FC<ErrorMessageProp> = ({message, cssClass}: ErrorMessageProp): JSX.Element => {
    return (
        <Grid item xs={12}>
            <h1 data-test="error-message" className={cssClass ? cssClass : 'default'}>{message}</h1>
        </Grid>
    )
}

export default ErrorMessage;