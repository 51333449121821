import { FC } from "react";
import { Card, Typography } from "@cfa/react-components";
import { TripDay } from "@cfacorp/business-travel-api-client";
import {
  tripDateOutput,
} from "../../../../../utils/helpers/tripCardHelper";
import { useHistory } from "react-router-dom";
import styles from "./ProcessedTripCard.module.scss";
import LocationText from "../../../../../components/LocationText/LocationText";
import { useDelegateContext } from "../../../../../context/DelegateContext";

interface ProcessedTripCardProps {
  tripDays: TripDay[];
  isClickable?: boolean;
}

const ProcessedTripCard: FC<ProcessedTripCardProps> = ({ 
  tripDays,
  isClickable = true }: ProcessedTripCardProps) => {
  const { selectedUser, isDelegate } = useDelegateContext();
  const tripId = tripDays[0].tripId;
  const history = useHistory();
  const handleCardClick = () => {
    if (!tripId) {
      console.warn("No tripId Found");
      return;
    }

    if (isClickable) {
      const tripStatus = tripDays[0]?.tripStatus;
      history.push({
        pathname: `/trip/${tripId}`,
      });
    }
  };
  return (
    <Card
      key={`${tripDays[0].tripId}-${tripDays[0].date}`}
      elevation={2}
      className={`${styles.container} ${isDelegate ? styles.delegateContainer : ""}`}
      onClick={isClickable ? handleCardClick : undefined}
      style={{ cursor: isClickable ? "pointer" : "default" }}
      data-testid={"processed-trip-card"}
    >
      <LocationText tripDays={tripDays} variant={"body1"} />
      <Typography variant={"body1"} className={styles.textMiddleRow}>
        {tripDateOutput(tripDays).formattedRange}
      </Typography>
      <Typography variant={"body2"}>Processed</Typography>
    </Card>
  );
};

export default ProcessedTripCard;
