import I18n from "../../i18n/utils";
import './footer.scss'

const Footer = () => {
    return (
        <footer className={"footer"}>
            <a href={'/Deloitte Privacy Notice_13Apr24.pdf'}>{I18n.t("PRIVACY_NOTICE")}</a>
        </footer>
    );
};

export default Footer;