import { FC, useState, useEffect } from "react";
import {
  Logo,
  TopNavigation,
  Typography,
  TopNavigationContent,
} from "@cfa/react-components";
import styles from "./Header.module.scss";
import NavigationTabs from "./components/NavigationTabs/NavigationTabs";
import Profile from "./components/Profile/Profile";
import { useLocation } from "react-router-dom";
import Toaster from "../Toaster/Toaster";

const Header: FC = () => {
  const location = useLocation();
  const [isOnTripDetailsPage, setIsOnTripDetailsPage] = useState(false);

  useEffect(() => {
    setIsOnTripDetailsPage(location.pathname.startsWith("/trip"));
  }, [location.pathname]);

  return (
    <div className={styles.headerContainer}>
      <Toaster />
      <TopNavigation
        color="primary"
        position="sticky"
        elevation={0}
        className={styles.topNavContainer}
      >
        <TopNavigationContent section="start">
          {isOnTripDetailsPage ? (
            <span aria-label="Home (disabled)">
              <Logo logo="symbol" size="md" />
            </span>
          ) : (
            <a href="/" aria-label="Navigate to home">
              <Logo logo="symbol" size="md" />
            </a>
          )}
        </TopNavigationContent>
        <TopNavigationContent section="middle">
          <Typography variant="h3" color="secondary">
            Business Travel Validation Tool
          </Typography>
        </TopNavigationContent>
        <TopNavigationContent section="end">
          <Profile />
        </TopNavigationContent>
      </TopNavigation>
      <div className={`${isOnTripDetailsPage ? styles.hiddenNavBar : ""}`}>
        {!isOnTripDetailsPage && <NavigationTabs />}
      </div>
    </div>
  );
};

export default Header;
