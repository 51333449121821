import { FC } from "react";
import { Card, Typography } from "@cfa/react-components";
import { isValidationWindowOpen } from "../../utils/validationWindowHelper";
import styles from "./WindowWidget.module.scss";

const WindowWidget: FC = () => {
  return (
    <Card elevation={4} className={styles.card}>
      <Typography variant={"h3"} className={styles.headerPadding}>
        Validation Info
      </Typography>
      {isValidationWindowOpen() ? (
        <>
          <Typography variant={"body2"} className={styles.bodyPadding}>
            The validation window is currently:{" "}
            <span className={styles.windowOpen}>OPEN</span>
          </Typography>
          <Typography variant={"body2"} className={styles.bodyPadding}>
            The current validation window is for trips that ended before this
            month.
          </Typography>
          <Typography variant={"body2"} className={styles.bodyPadding}>
            These trips must be validated by the 21st. Any trips not validated
            by then are automatically processed and cannot be changed.
          </Typography>
        </>
      ) : (
        <>
          <Typography variant={"body2"} className={styles.bodyPadding}>
            The validation window is currently:{" "}
            <span className={styles.windowClosed}>CLOSED</span>
          </Typography>
          <Typography variant={"body2"} className={styles.bodyPadding}>
            Trips become available for validation on the 10th of the following
            month.
          </Typography>
        </>
      )}
    </Card>
  );
};
export default WindowWidget;
