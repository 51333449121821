import OktaCookie from "../interfaces/OktaCookie";

type LocalStorageKeys = "okta-token-storage" | "masqueraded-user";

const OKTA_TOKEN_STORAGE = "okta-token-storage";

const getLocalStorageValue = (key: LocalStorageKeys): string => {
  const value: string | null = localStorage.getItem(key);

  return value ? value : "";
};

const getOktaAccessToken = (): string => {
  const oktaCookie: string = getLocalStorageValue(OKTA_TOKEN_STORAGE);
  let accessToken: string = "";

  if (oktaCookie) {
    const token: OktaCookie = JSON.parse(oktaCookie);
    accessToken = token.accessToken.accessToken;
  }

  return accessToken;
};

const getOktaAccessName = (): string => {
  const oktaCookie: string = getLocalStorageValue(OKTA_TOKEN_STORAGE);
  let name: string = "";

  if (oktaCookie) {
    const token: OktaCookie = JSON.parse(oktaCookie);
    name = token.idToken.claims?.name || "";
  }
  return name;
};

const getOktaAccessFirstName = (): string => {
  const oktaCookie: string = getLocalStorageValue(OKTA_TOKEN_STORAGE);
  let firstName: string = "";

  if (oktaCookie) {
    const token: OktaCookie = JSON.parse(oktaCookie);
    firstName =
      token.idToken.claims?.nickname || token.idToken.claims?.firstName || "";
  }
  return firstName;
};

export { getOktaAccessToken, getOktaAccessName, getOktaAccessFirstName };
