import React from "react";
import {
  Modal,
  ModalHeader,
  ModalBody,
  ModalFooter,
  Button,
} from "@cfa/react-components";

interface ConfirmModalProps {
  onConfirm: () => void;
  onBack: () => void;
  isModalOpen: boolean;
}

const ConfirmationModal: React.FC<ConfirmModalProps> = ({
  onConfirm,
  onBack,
  isModalOpen,
}) => (
  <Modal
    onClose={onBack}
    scrollMode="modal-body"
    size="sm"
    show={isModalOpen}
  >
    <ModalHeader>Quit Editing?</ModalHeader>
    <ModalBody>Changes you made so far will not be saved</ModalBody>
    <ModalFooter>
      <Button variant="outlined" onClick={onBack}>
        Continue editing
      </Button>
      <Button variant="filled" onClick={onConfirm}>
        Discard changes
      </Button>
    </ModalFooter>
  </Modal>
);

export default ConfirmationModal;
