import { Dispatch, FC, useEffect } from "react";
import { IconFilter, IconX } from "@tabler/icons-react";
import { Typography, Card, Dropdown, Multiselect } from "@cfa/react-components";
import styles from "./FilterWidget.module.scss";
import { TripDay } from "@cfacorp/business-travel-api-client";
import { getLocations, getTaxYears } from "../../../utils/filterUtils";
import { FilterAction, FilterState } from "../../../hooks/useFilter";
import { useDelegateContext } from "../../../../../context/DelegateContext";

interface FilterWidgetProps {
  trips: TripDay[];
  filterState: FilterState;
  dispatchFilter: Dispatch<FilterAction>;
  shouldDefaultCurrentYear?: boolean;
  isJustTaxYear?: boolean;
}

const FilterWidget: FC<FilterWidgetProps> = ({
  trips,
  filterState,
  dispatchFilter,
  shouldDefaultCurrentYear = false,
  isJustTaxYear,
}) => {
  const { selectedUser } = useDelegateContext();
  useEffect(() => {
    dispatchFilter({ type: "CLEAR_ALL", payload: null });
  }, [selectedUser]);

  return (
    <Card elevation={4} className={styles.container}>
      <div className={styles.header}>
        <IconFilter size={35} stroke={2} />
        <Typography className={styles.headerText} variant={"h2"}>
          Filters
        </Typography>
      </div>
      <div className={styles.filtersBody}>
        <div className={styles.yearFilter}>
          <Dropdown
            className={styles.filterDropDown}
            options={
              shouldDefaultCurrentYear
                ? Array.from(
                    new Set([
                      new Date().getFullYear().toString(),
                      ...getTaxYears(trips),
                    ]),
                  )
                : getTaxYears(trips)
            }
            value={filterState.taxYear}
            onChange={(val) => {
              dispatchFilter({ type: "SET_TAX_YEAR", payload: val });
            }}
            label={"Validation Year"}
            renderOption={(option) => (
              <div className={styles.dropDownOptionText}>{option}</div>
            )}
          />
          {!shouldDefaultCurrentYear &&
            filterState &&
            filterState.taxYear !== "" && (
              <IconX
                onClick={() =>
                  dispatchFilter({ type: "SET_TAX_YEAR", payload: "" })
                }
                className={styles.xButton}
                size={23}
                stroke={2}
              />
            )}
        </div>
        {!isJustTaxYear && (
          <div className={styles.locationsFilter}>
            <Multiselect
              options={getLocations(trips)}
              value={filterState.location}
              onChange={(val) =>
                dispatchFilter({ type: "SET_LOCATION", payload: val })
              }
              label={"Location"}
              getOptionText={(option) =>
                option?.countryCode === "US" || option?.countryCode === "CA"
                  ? `${option?.state}, ${option?.countryCode}`
                  : option?.country || ""
              }
              getOptionId={(option) =>
                `${option?.countryCode}${option?.stateCode}`
              }
            />
          </div>
        )}
      </div>
    </Card>
  );
};

export default FilterWidget;
