import styles from "./Thresholds.module.scss";
import classNames from "classnames";
import { Container, Card, Typography } from "@cfa/react-components";
import { FC } from "react";
import LocationsTable from "./ThresholdsTable/ThresholdsTable";
import { useGetUserTravelLocations } from "../../../../hooks/api/trips/useGetUserTravelLocations";
import { useDelegateContext } from "../../../../context/DelegateContext";
import FilterWidget from "../ProcessedTrips/FilterWidget/FilterWidget";
import { useProcessedTripDaysQuery } from "../../../../hooks/api/trips/useProcessedTripDaysQuery";
import { useFilter } from "../../hooks/useFilter";
import { filterTrips } from "../../utils/filterUtils";

const Thresholds: FC = () => {
  const { data: tripDays } = useProcessedTripDaysQuery();
  const { state: filterState, dispatch } = useFilter(true);
  const { delegateUsers, selectedUser: selectedPerson } = useDelegateContext();
  const {
    data: locations,
    isLoading: loading,
    isError: error,
  } = useGetUserTravelLocations(
    selectedPerson?.personId || (delegateUsers && delegateUsers[0]?.personId),
    filterState.taxYear,
  );
  const filteredTrips = filterTrips(tripDays || [], filterState);

  return (
    <div
      className={styles.thresholdsTripsContainer}
      data-testid={"thresholds-container"}
    >
      <div className={styles.leftWidgetContainer}>
        <Card className={classNames(styles.card)} elevation={4}>
          <Typography variant={"body1"}>
            The dashboard below provides a summary of year-to-date trips.
            <br />
            <br />
            Trip data is displayed on one of two tables: the US Domestic Trips
            table shows data for working days in the 50 US States plus
            Washington, D.C., and the International and US Territories Trips
            table shows data for working days in other countries and US
            territories.
            <br />
            <br />
          </Typography>
          <Typography variant={"h4"}>
            The data for the table columns:
          </Typography>
          <Typography className={styles.dashboardHelpText}>
            <b>Location:</b> Location where working days have occurred
            <br />
          </Typography>
          <Typography className={styles.dashboardHelpText}>
            <b>Unvalidated working days:</b> Sum of working days that have not
            yet been validated in current validation period
            <br />
          </Typography>
          <Typography className={styles.dashboardHelpText}>
            <b>Validated working days:</b> Sum of working days that have been
            validated in current validation period
            <br />
          </Typography>
          <Typography className={styles.dashboardHelpText}>
            <b>Processed working days:</b> Sum of working days that have been
            validated in a prior validation period
            <br />
          </Typography>
          <Typography className={styles.dashboardHelpText}>
            <b>Total working days:</b> Sum of unvalidated, validated, and
            processed working days
            <br />
          </Typography>
          <Typography className={styles.dashboardHelpText}>
            <b>State threshold:</b> Withholding/reporting working day threshold of state
            <br />
          </Typography>
        </Card>
        <LocationsTable
          type={"domestic"}
          data={locations?.filter((i) => i.country === "United States") || []}
          isLoading={loading}
          isError={error}
        />
        {locations?.some((i) => i.country !== "United States") && (
          <LocationsTable
            type={"international"}
            data={locations?.filter((i) => i.country !== "United States") || []}
            isLoading={loading}
            isError={error}
          />
        )}
      </div>
      <div className={styles.rightWidgetContainer}>
        <FilterWidget
          trips={
            tripDays?.filter(
              (trip) => trip.personId === selectedPerson?.personId,
            ) || []
          }
          filterState={filterState}
          dispatchFilter={dispatch}
          shouldDefaultCurrentYear={true}
          isJustTaxYear={true}
        />
      </div>
    </div>
  );
};
export default Thresholds;
