import { TripDay } from "@cfacorp/business-travel-api-client";
import { FilterState } from "../hooks/useFilter";
import { LOCATIONS } from "../../TripDetails/constants/Locations";
import { Location } from "../../TripDetails/utils/tripDaySchemas";

export const getTaxYears = (tripDays: TripDay[]): string[] => {
  const taxYears = tripDays
    .map((tripDay) => tripDay.taxYear)
    .filter((taxYear) => !!taxYear) as string[];

  const uniqueTaxYears = Array.from(new Set(taxYears)).sort((a, b) =>
    a.localeCompare(b),
  );
  const oldestTaxYear = parseInt(uniqueTaxYears[0], 10);
  const currentYear = new Date().getFullYear();

  return Array.from({ length: currentYear - oldestTaxYear + 1 }, (_, i) =>
    (currentYear - i).toString(),
  );
};

export const getLocations = (tripDays: TripDay[]): Location[] => {
  const locations = tripDays
    .map((tripDay) =>
      tripDay.countryCode
        ? LOCATIONS.find((location) =>
            tripDay.countryCode === "US" || tripDay.countryCode === "CA"
              ? location?.stateCode === tripDay.stateCode
              : location?.countryCode === tripDay.countryCode,
          )
        : null,
    )
    .filter((location) => !!location) as Location[];

  return Array.from(new Set(locations)).sort((a, b) => {
    if (!a || !b) return 0;
    return (a.state + a.country).localeCompare(b.state + b.country);
  });
};

export const filterTrips = (
  tripDays: TripDay[],
  filterState: FilterState,
): TripDay[] => {
  const locationTripIds = new Set(
    tripDays
      .filter((trip) =>
        filterState.location.length > 0
          ? filterState.location.some((loc) =>
              trip.countryCode === "US" || trip.countryCode === "CA"
                ? loc?.stateCode === trip.stateCode
                : loc?.countryCode === trip.countryCode,
            )
          : true,
      )
      .map((trip) => trip.tripId),
  );

  return (
    tripDays?.filter((trip) => {
      const matchesTaxYear =
        !filterState.taxYear || trip.taxYear === filterState.taxYear;

      const matchesLocation =
        filterState.location.length === 0 ||
        locationTripIds.has(trip.tripId || "");

      return matchesTaxYear && matchesLocation;
    }) || []
  );
};
