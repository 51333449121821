import { businessTravelService } from "../../../utils/API";
import { useQuery } from "@tanstack/react-query";
import { Person } from "@cfacorp/business-travel-api-client";
import DelegateUser from "../../../interfaces/DelegateUser";
import { getFirstName, getLastName } from "../../../utils/helpers/PersonHelper";

const formatDelegateUsers = (people: Person[]): DelegateUser[] => {
  return people.map((person) => {
    const user: DelegateUser = {
      cfaId: person.cfaId || "",
      personId: person.personId || "",
      firstName: getFirstName(person),
      lastName: getLastName(person),
    };
    return user;
  });
};

function useGetUserDelegates() {
  const getUserDelegates = async () => {
    const response = await businessTravelService.getDelegatesForContextUser();
    return formatDelegateUsers(response);
  };

  return useQuery({
    queryKey: ["delegates"],
    queryFn: getUserDelegates,
    staleTime: 86400000,
  });
}
export { useGetUserDelegates };
