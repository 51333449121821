import { getOktaAccessToken } from "../LocalStorage";

const retrieveAuthUserCfaId = (encryptedJWTAccessToken: string) => {
  return parseJwt(encryptedJWTAccessToken)?.cfa_guid;
};
const retrieveAuthUserId = (encryptedJWTAccessToken: string) => {
  return parseJwt(encryptedJWTAccessToken)?.sub;
};

const decodeAccessToken = (encryptedJWTAccessToken: string) => {
  return parseJwt(encryptedJWTAccessToken);
};

const parseJwt = (token: string) => {
  let json;
  try {
    const base64Url = token.split(".")[1];
    const base64 = base64Url.replace("-", "+").replace("_", "/");
    json = JSON.parse(window.atob(base64));
  } catch (e) {
    console.error("error parsing jwt " + String(e));
  }
  return json;
};

const isAdmin = (): boolean => {
  const token = decodeAccessToken(getOktaAccessToken());
  if (token === undefined || !("cfa_perms" in token)) return false;
  return token["cfa_perms"]["1393_TRAVEL_TAX"]["ADMIN"] !== undefined;
};

export {
  retrieveAuthUserCfaId,
  retrieveAuthUserId,
  decodeAccessToken,
  isAdmin,
};
