import { useMutation, useQueryClient } from "@tanstack/react-query";
import { businessTravelService } from "../../../utils/API";
import { TravelValidationConf } from "@cfacorp/business-travel-api-client";

const useUpdateUserConfiguration = () => {
  const queryClient = useQueryClient();
  const updateUserConfiguration = async (configuration: TravelValidationConf) =>
    await businessTravelService.saveUserConfiguration({
      requestBody: configuration,
    });

  return useMutation({
    mutationFn: updateUserConfiguration,
    onSuccess: () =>
      queryClient.invalidateQueries({ queryKey: ["Configuration"] }),
  });
};

export { useUpdateUserConfiguration };
