import { FC, useState } from "react";
import { Button } from "@cfa/react-components";
import styles from "./AddNewTripButton.module.scss";
import { useDelegateContext } from "../../context/DelegateContext";
import { useHistory } from "react-router-dom";
import CreationReasonModal, {
  CreationReason,
} from "./components/CreationReasonModal/CreationReasonModal";

interface AddNewTripButtonProps {
  className?: string;
}

const AddNewTripButton: FC<AddNewTripButtonProps> = ({ className }) => {
  const { isDelegate } = useDelegateContext();
  const [isModalOpen, setIsModalOpen] = useState(false);
  const history = useHistory();

  const navigateToTrip = (reason: CreationReason) => {
    history.push({
      pathname: "/trip",
      state: { creationReason: reason },
    });
  };

  return (
    <>
      <Button
        onClick={() => setIsModalOpen(true)}
        className={`${isDelegate ? styles.delegateAddTripButton : ""} ${className}`}
      >
        Add new trip
      </Button>
      <CreationReasonModal
        onCreate={(reason) => navigateToTrip(reason)}
        onBack={() => setIsModalOpen(false)}
        isModalOpen={isModalOpen}
      />
    </>
  );
};

export default AddNewTripButton;
