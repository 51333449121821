import React, { useEffect } from "react";
import {
  Dialog,
  DialogTitle,
  DialogContent,
  DialogContentText,
  DialogActions,
  Button,
} from "@mui/material";
import I8ln from "../../../../i18n/utils";
import { useUpdateAcknowledge } from "../../../../hooks/api/delegates";

const DelegateDisclaimerDialog: React.FC<{ acknowledge?: boolean }> = (
  props,
): JSX.Element => {
  const [open, setOpen] = React.useState<boolean>(false);
  const { mutate: setAckTrue } = useUpdateAcknowledge();

  useEffect(() => {
    if (props.acknowledge) {
      setOpen(false);
    } else {
      setOpen(true);
    }
  }, [props.acknowledge]);

  const handleClose = () => {
    setOpen(false);
    setAckTrue(true);
  };

  return (
    <Dialog open={open}>
      <DialogTitle>{I8ln.t("APP_DELEGATE_DISCLAIMER_TITLE")}</DialogTitle>
      <DialogContent>
        <DialogContentText>
          <span>{I8ln.t("APP_DELEGATE_DISCLAIMER")}</span>
        </DialogContentText>
      </DialogContent>
      <DialogActions>
        <Button
          className="acceptButton"
          onClick={handleClose}
          autoFocus
          data-test="accept-discalimer"
        >
          {I8ln.t("APP_DELEGATE_DISCLAIMER_ACCEPT")}
        </Button>
      </DialogActions>
    </Dialog>
  );
};

export default DelegateDisclaimerDialog;
