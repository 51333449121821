import styles from "./TripDetailsButtons.module.scss";
import { Button } from "@cfa/react-components";
import { FC } from "react";
import { TripDay } from "@cfacorp/business-travel-api-client";
import statuses = TripDay.tripStatus;

interface TripDetailsButtonsProps {
  onBack: () => void;
  onLooksGood: () => void;
  onDidntGo: () => void;
  submitDisabled: boolean;
  tripStatus: TripDay.tripStatus | undefined;
}

const TripDetailsButtons: FC<TripDetailsButtonsProps> = ({
  onBack,
  onLooksGood,
  onDidntGo,
  submitDisabled,
  tripStatus,
}) => {
  return (
    <div className={styles.buttonContainer}>
      {(tripStatus === statuses.OPEN || tripStatus === statuses.VERIFIED) && (
        <Button color="secondary" className={styles.button} onClick={onDidntGo}>
          Didn't go on this trip
        </Button>
      )}
      <Button
        variant="outlined"
        className={
          tripStatus === statuses.PROCESSED
            ? styles.bigBackButton
            : styles.button
        }
        onClick={onBack}
      >
        Back
      </Button>
      {tripStatus !== statuses.PROCESSED && (
        <Button
          className={styles.button}
          onClick={onLooksGood}
          disabled={submitDisabled}
        >
          Looks good
        </Button>
      )}
    </div>
  );
};

export default TripDetailsButtons;
