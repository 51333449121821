import React, { useEffect, useState } from "react";
import {
  AutocompleteChangeReason,
  CircularProgress,
  Grid,
  TextField,
} from "@mui/material";
import "./delegate.scss";
import { Autocomplete } from "@mui/material";
import I8ln from "../../i18n/utils";
import SaveActionDialog from "./components/SaveActionDialog/SaveActionDialog";
import { Person } from "@cfacorp/business-travel-api-client";
import DelegateDisclaimerDialog from "./components/DelegateDisclaimerDialog/DelegateDisclaimerDialog";
import { getFullName } from "../../utils/helpers/PersonHelper";
import { useGetPeople } from "../../hooks/api/people/useGetPeople";
import { useGetUserConfiguration } from "../../hooks/api/delegates";

const Delegate: React.FC = (): JSX.Element => {
  /**** Autocomplete related attributes ****/
  const [open, setOpen] = useState(false);
  const [textLabel, setTextLabel] = useState<string>("Choose a delegate");
  const [isUpdatable, setIsUpdatable] = useState<boolean>(false);
  /******************** ***********************/
  const [currentDelegates, setCurrentDelegates] = useState<Person[]>([]);
  const [selectedDelegates, setSelectedDelegates] = useState<Person[]>([]);

  const [errorMessage, setErrorMessage] = useState<string>("");

  const { data: options, isError: isPeopleError } = useGetPeople();

  //get user configuration, find person object in options list, should only run once when page is loaded and we don't know delegate person
  const { data: configuration, isError: isConfigurationError } =
    useGetUserConfiguration();
  const [areCurrentDelegatesLoaded, setAreCurrentDelegatesLoaded] =
    useState<boolean>(false);
  useEffect(() => {
    if (configuration && options && options.length > 0) {
      if (configuration.delegateCfaId) {
        const idx = options.findIndex((person) => {
          return person.cfaId === configuration.delegateCfaId;
        });
        if (idx >= 0) {
          setCurrentDelegates([options[idx]]);
          setSelectedDelegates([options[idx]]);
        } else {
          setErrorMessage(I8ln.t("FIND_DELEGATE_ERROR"));
        }
      } else {
        setSelectedDelegates([]);
        setCurrentDelegates([]);
      }
      setAreCurrentDelegatesLoaded(true);
    }
  }, [configuration, options]);

  useEffect(() => {
    if (isPeopleError) {
      setErrorMessage(I8ln.t("GET_PEOPLE_ERROR"));
    } else if (isConfigurationError) {
      setSelectedDelegates([]);
      setCurrentDelegates([]);
      setErrorMessage(I8ln.t("GET_CONFIGURATION_ERROR"));
    } else {
      setErrorMessage("");
    }
  }, [isConfigurationError, isPeopleError]);

  //determine autocomplete text label
  useEffect(() => {
    if (selectedDelegates.length === 0) {
      setTextLabel("Choose a delegate");
    } else if (selectedDelegates[0] === currentDelegates[0]) {
      setTextLabel("Current delegate");
    } else {
      setTextLabel("Selected delegate");
    }
  }, [selectedDelegates, currentDelegates]);

  const deletePerson = () => {
    setSelectedDelegates([]);
    setIsUpdatable(true);
  };

  const addPerson = (personToAdd: any) => {
    if (personToAdd !== null) {
      setSelectedDelegates([personToAdd]);
      if (!currentDelegates.includes(personToAdd)) {
        setIsUpdatable(true);
      } else {
        setIsUpdatable(false);
      }
    }
  };

  const refreshView = (): void => {
    setCurrentDelegates(selectedDelegates);
    setErrorMessage("");
    setIsUpdatable(false);
  };

  const revertView = (): void => {
    setSelectedDelegates(currentDelegates);
    setErrorMessage("");
    setIsUpdatable(false);
  };

  const handleFailSave = (): void => {
    setErrorMessage(I8ln.t("DELEGATE_SAVE_ERROR"));
  };

  const renderAutocomplete = () => {
    return (
      <Grid className={"delegateSearchContainer"}>
        <Autocomplete
          id="delegate-selector"
          className={"delegateSearch"}
          open={open}
          value={selectedDelegates[0] || null}
          onChange={(event, value, reason) => {
            if (reason === "selectOption") {
              addPerson(value);
            } else {
              deletePerson();
            }
          }}
          onOpen={() => {
            setOpen(true);
          }}
          onClose={() => {
            setOpen(false);
          }}
          // TODO: I probably broke this, look into this prop
          // getOptionSelected={(option, value) => option?.cfaId === value?.cfaId}
          getOptionLabel={(option) =>
            getFullName(option) + " (" + option?.email + ")"
          }
          getOptionDisabled={(option) => option?.cfaId === configuration?.cfaId}
          options={options || []}
          loading={areCurrentDelegatesLoaded}
          renderInput={(params) => (
            <TextField
              {...params}
              label={textLabel}
              variant="outlined"
              InputProps={{
                ...params.InputProps,
                endAdornment: (
                  <React.Fragment>
                    {!areCurrentDelegatesLoaded ? (
                      <CircularProgress color="inherit" size={20} />
                    ) : null}
                    {params.InputProps.endAdornment}
                  </React.Fragment>
                ),
              }}
            />
          )}
        />
      </Grid>
    );
  };

  return (
    <Grid container direction={"column"}>
      {areCurrentDelegatesLoaded && (
        <DelegateDisclaimerDialog acknowledge={configuration?.acknowledge} />
      )}

      <Grid item xs={12} className={"instructionsText"}>
        <span>{I8ln.t("APP_DELEGATE_PICKER_INSTRUCTIONS")}</span>
      </Grid>

      {renderAutocomplete()}

      {errorMessage !== "" && (
        <Grid item xs={12} className={"errorText"}>
          <span>{errorMessage}</span>
        </Grid>
      )}

      {areCurrentDelegatesLoaded && (
        <SaveActionDialog
          selectedDelegates={selectedDelegates}
          currentDelegates={currentDelegates}
          isUpdatable={isUpdatable}
          savedConfiguration={configuration}
          refreshView={refreshView}
          revertView={revertView}
          handleFailSave={handleFailSave}
        />
      )}
    </Grid>
  );
};

export default Delegate;
