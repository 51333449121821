import { FC } from "react";
import { useOktaAuth } from "@okta/okta-react/";
import {
  getOktaAccessName,
  getOktaAccessFirstName,
} from "../../../../utils/LocalStorage";
import {
  Avatar,
  Logo,
  PopoverMenu,
  PopoverMenuContent,
  PopoverMenuItem,
  PopoverMenuToggleButton,
  TopNavigation,
  Typography,
  TopNavigationContent,
} from "@cfa/react-components";
import styles from "./Profile.module.scss";

const Profile: FC = () => {
  const { authState, oktaAuth } = useOktaAuth();

  return (
    <div className={styles.profileContainer}>
      <Typography variant="body1" className={styles.nameTextPadding}>
        Hello, {authState?.isAuthenticated ? getOktaAccessFirstName() : ""}
      </Typography>
      <PopoverMenu placement="bottom-end">
        <PopoverMenuToggleButton
          as={Avatar}
          radii="md"
          size="md"
          name={authState?.isAuthenticated ? getOktaAccessName() : ""}
        />
        <PopoverMenuContent>
          {authState?.isAuthenticated ? (
            <>
              <PopoverMenuItem id="signout" onClick={() => oktaAuth.signOut()}>
                Sign Out
              </PopoverMenuItem>
            </>
          ) : (
            <PopoverMenuItem
              id="signin"
              onClick={() => oktaAuth.signInWithRedirect({ originalUri: "/" })}
            >
              Sign In
            </PopoverMenuItem>
          )}
        </PopoverMenuContent>
      </PopoverMenu>
    </div>
  );
};
export default Profile;
