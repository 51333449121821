import { FC, ReactNode } from "react";
import { Card, Typography, Link } from "@cfa/react-components";
import styles from "./HelpWidget.module.scss";

interface HelpWidgetProps {
  additionalText?: string | ReactNode;
}

const HelpWidget: FC<HelpWidgetProps> = ({ additionalText }) => {
  return (
    <Card elevation={4} className={styles.card}>
      <Typography variant={"h3"} className={styles.headerPadding}>
        Help
      </Typography>
      {additionalText && (
        <Typography variant={"body1"} className={styles.bodyPadding}>
          {additionalText}
        </Typography>
      )}
      <Link
        href="https://portal.cfahome.com/en/corporate-resources/corporate-travel/business-travel-validation-tool/#TopicConten-Frequently-Asked-Questions-for-Impacted-Staff"
        target="_blank"
        variant="standalone"
        className={styles.bodyPadding}
      >
        FAQ
      </Link>
      <Link
        href="https://portal.cfahome.com/en/corporate-resources/corporate-travel/business-travel-validation-tool/"
        target="_blank"
        variant="standalone"
        className={styles.bodyPadding}
      >
        BTVT Resources
      </Link>
      <Link
        href="https://cfaprod.service-now.com/servicegateway?id=cfastaff_sc_cat_item&sys_id=92c78de49f331200d9011977677fcf7b"
        target="_blank"
        variant="standalone"
        className={styles.bodyPadding}
      >
        Submit ticket on Service Gateway
      </Link>
    </Card>
  );
};
export default HelpWidget;
