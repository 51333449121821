import { FC } from "react";
import { TripDay } from "@cfacorp/business-travel-api-client";
import {
  getUniqueLocations,
  locationStringOutput,
} from "../../utils/helpers/tripCardHelper";
import { Typography, TypographyVariant } from "@cfa/react-components";
import styles from "./LocationText.module.scss";

interface LocationTextProps {
  tripDays: TripDay[];
  className?: string;
  variant?: TypographyVariant;
  fontWeight?: "bold" | "extralight" | "regular" | "medium" | "extrabold";
}

const LocationText: FC<LocationTextProps> = ({
  tripDays,
  className,
  variant,
  fontWeight,
}) => {
  const uniqueLocations = getUniqueLocations(tripDays);
  const locations = locationStringOutput(tripDays);

  return (
    <div
      className={locations.needsToolTip ? styles.tooltipWrapper : ""}
      data-tooltip={locations.needsToolTip ? uniqueLocations.join(", ") : ""}
    >
      <Typography
        className={className}
        variant={variant || "caption1"}
        fontWeight={fontWeight || "bold"}
      >
        {locations.locationsText}
      </Typography>
    </div>
  );
};

export default LocationText;
