import { businessTravelService } from "../../../utils/API";
import { useQuery } from "@tanstack/react-query";

const useGetUserConfiguration = () => {
  const getUserConfiguration = async () =>
    await businessTravelService.getConfigurationForContextUser();

  return useQuery({
    queryKey: ["Configuration"],
    queryFn: getUserConfiguration,
  });
};

export { useGetUserConfiguration };
