import { TripDay } from "@cfacorp/business-travel-api-client";
import { z } from "zod";
import dayType = TripDay.dayType;
import tripStatus = TripDay.tripStatus;

const workType = z
  .object({
    label: z.string().optional(),
    dayType: z.enum([dayType.WORK, dayType.NON_WORK]),
    dayReason: z.string().optional(),
  })
  .refine((schema) => {
    return schema.dayType !== dayType.NON_WORK || schema.dayReason;
  }, "Day reason is required for non-work days");

const location = z
  .object({
    countryCode: z.string().nonempty("Country is required"),
    country: z.string().nonempty("Country name is required"),
    stateCode: z.string().optional(),
    state: z.string().optional(),
  })
  .refine((schema) => {
    return (
      (schema.countryCode !== "US" && schema.countryCode !== "CA") ||
      schema.stateCode
    );
  }, "State is required for US and CA trips")
  .nullable();

const tripDaySchema = z.object({
  personId: z.string(),
  date: z.string(),
  tripId: z.string().optional(),
  day: z.string().optional(),
  cfaId: z.string().optional(),
  creationReason: z.string().optional(),
  tripStatus: z
    .enum([tripStatus.OPEN, tripStatus.PROCESSED, tripStatus.VERIFIED])
    .optional(),
  workType: workType,
  location: location,
});

export const tripDaysSchema = z.object({
  tripDays: z.array(tripDaySchema),
});

export type DayField = z.infer<typeof tripDaySchema>;
export type Trip = z.infer<typeof tripDaysSchema>;
export type WorkType = z.infer<typeof workType>;
export type Location = z.infer<typeof location>;
