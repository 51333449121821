import {
  SearchDropdown,
  SearchDropdownProps,
  SearchDropdownRenderedOption,
  Typography,
} from "@cfa/react-components";
import { FC } from "react";
import { Location } from "../../utils/tripDaySchemas";
import { LOCATIONS } from "../../constants/Locations";

interface LocationSearchDropdownProps {
  value: Location;
  onChange: (value: Location | undefined) => void;
  label: string;
  disabled: boolean;
  className?: string;
  hasLocationError?: boolean;
}

const LocationSearchDropdown: FC<LocationSearchDropdownProps> = ({
  value,
  onChange,
  label,
  disabled,
  className,
  hasLocationError,
}) => {
  const handleSearch: SearchDropdownProps<Location>["onSearch"] = async (
    query,
  ) => {
    const results = LOCATIONS.filter(
      (location) =>
        location &&
        (location.state?.toLowerCase().includes(query.toLowerCase()) ||
          location.country.toLowerCase().includes(query.toLowerCase())),
    );
    return { canLoadMore: false, results };
  };

  const renderOption = (option: Location): SearchDropdownRenderedOption => ({
    id: `${option?.countryCode}-${option?.stateCode}`,
    textValue:
      option?.countryCode === "US" || option?.countryCode === "CA"
        ? `${option.state}, ${option.countryCode}`
        : option?.country || "",
    rendered: (
      <Typography>
        {option?.countryCode === "US" || option?.countryCode === "CA"
          ? `${option?.state}, ${option?.countryCode}`
          : option?.country}
      </Typography>
    ),
  });

  return (
    <SearchDropdown
      className={className}
      value={value}
      label={label}
      disabled={disabled}
      compact={false}
      required={true}
      hideRequiredStyle
      errorText={hasLocationError ? "Location Required" : ""}
      onChange={onChange}
      onSearch={handleSearch}
      renderOption={renderOption}
      placeholder="Search for a location"
      debounceDelay={200}
      renderNoResults={(query) => (
        <Typography>No results found for "{query}"</Typography>
      )}
      renderError={(error) => <Typography>Error: {error.message}</Typography>}
    />
  );
};

export default LocationSearchDropdown;
