import { businessTravelService } from "../../../utils/API";
import { useMutation, useQueryClient } from "@tanstack/react-query";

const useUpdateAcknowledge = () => {
  const queryClient = useQueryClient();
  const updateAcknowledge = async (didAck: boolean = true) =>
    await businessTravelService.acknowledgeConfiguration({ ack: didAck });

  return useMutation({
    mutationFn: updateAcknowledge,
    onSuccess: () =>
      queryClient.invalidateQueries({ queryKey: ["Configuration"] }),
  });
};

export { useUpdateAcknowledge };
