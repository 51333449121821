import { FC } from "react";
import styles from "./Home.module.scss";
import { Typography } from "@cfa/react-components";
import WindowWidget from "./components/WindowWidget/WindowWidget";
import HelpWidget from "../../components/HelpWidget/HelpWidget";
import TripsWidget from "./components/TripsWidget/TripsWidget";
import Footer from "../../components/Footer/Footer";
import { useDelegateContext } from "../../context/DelegateContext";
import FirstTimeWelcomeModal from "./components/FirstTimeWelcome/FIrstTimeWelcomeModal";
import DelegateSelectDropDown from "../../components/DelegateSelect/DelegateSelectDropDown";
import ConfettiButton from "../../components/Celebration/Celebration";

const Home: FC = () => {
  const { delegateUsers, isLoading: isDelegatesLoading } = useDelegateContext();

  return (
    <>
      <FirstTimeWelcomeModal />
      <div className={styles.masterContainer}>
        <div className={styles.headerContainer}>
          <Typography variant="h2">Welcome</Typography>
          {!isDelegatesLoading && delegateUsers && delegateUsers.length > 1 && (
            <div className={styles.headerRightContainer}>
              <DelegateSelectDropDown/>
            </div>
          )}
        </div>
        <div className={styles.subHeaderContainer}>
          <div className={styles.leftWidgetContainer}>
            <TripsWidget />
          </div>
          <div className={styles.rightWidgetContainer}>
            <WindowWidget />
            <HelpWidget />
          </div>
        </div>
      </div>
      <Footer />
    </>
  );
};
export default Home;
