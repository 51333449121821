import {
  Modal,
  ModalHeader,
  ModalBody,
  ModalFooter,
  Button,
  Link,
  Icon
} from "@cfa/react-components";
import React, { useState } from "react";
import cow from "../../../../assets/icons/image.png";
import { IconExternalLink } from "@tabler/icons-react";
import styles from "./FirstTimeModal.module.scss";

const FirstTimeWelcomeModal: React.FC = () => {
  const hasSeenModal = localStorage.getItem("WelcomeModal");
  const [isModalVisible, setIsModalVisible] = useState(!hasSeenModal);

  if (!hasSeenModal) {
    localStorage.setItem("WelcomeModal", "true");
  }

  const handleClose = () => {
    setIsModalVisible(false);
  };

  return (
    <Modal show={isModalVisible} onClose={handleClose} size="md">
      <ModalHeader className={styles.header} >
        Welcome to our new design!
      </ModalHeader>
      <ModalBody className={styles.body}>
        We've changed our look with you in mind.
      </ModalBody>
      <ModalFooter>
        <div className={styles.container}>
          <Button
            variant="filled"
            href="https://portal.cfahome.com/globalassets/documents/staff/btvt-redesign-overview.pdf?cb=48fc64"
          >
            <IconExternalLink stroke={2} className={styles.icon}></IconExternalLink>
            See what's new
          </Button>
        </div>
      </ModalFooter>
    </Modal>
  );
};
export default FirstTimeWelcomeModal;
