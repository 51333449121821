import { FC, useState } from "react";
import {
  Modal,
  ModalHeader,
  ModalFooter,
  Dropdown,
  Button,
} from "@cfa/react-components";
import styles from "./CreationReasonModal.module.scss";

interface CreationReasonModalProp {
  onCreate: (reason: CreationReason) => void;
  onBack: () => void;
  isModalOpen: boolean;
}

export type CreationReason =
  | "Personal vehicle travel"
  | "Work during personal travel"
  | "Voluntary relocation travel to Support Center"
  | "Forgot to use Direct Travel";

const creationReasonOptions: CreationReason[] = [
  "Personal vehicle travel",
  "Work during personal travel",
  "Voluntary relocation travel to Support Center",
  "Forgot to use Direct Travel",
];

const CreationReasonModal: FC<CreationReasonModalProp> = ({
  onCreate,
  onBack,
  isModalOpen,
}) => {
  const [selectedReason, setSelectedReason] = useState<CreationReason | null>(
    null,
  );

  return (
    <Modal
      onClose={onBack}
      scrollMode="modal-body"
      size="md"
      show={isModalOpen}
    >
      <ModalHeader>Why is this trip being created manually?</ModalHeader>
      <div className={styles.modalContent}>
        <Dropdown
          className={styles.dropdown}
          value={selectedReason}
          placeholder="Select a Reason"
          onChange={setSelectedReason}
          options={creationReasonOptions}
        />
      </div>
      <ModalFooter>
        <Button variant="outlined" onClick={onBack}>
          Back
        </Button>
        <Button
          variant="filled"
          onClick={() => selectedReason && onCreate(selectedReason)}
          disabled={!selectedReason}
        >
          Create
        </Button>
      </ModalFooter>
    </Modal>
  );
};

export default CreationReasonModal;
