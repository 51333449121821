import { useReducer } from "react";
import { Location } from "../../TripDetails/utils/tripDaySchemas";

export interface FilterState {
  taxYear: string;
  location: Location[]; // anotherFutureFilter: any;
}

export interface FilterAction {
  type: "SET_TAX_YEAR" | "CLEAR_ALL" | "SET_LOCATION"; // | "ANOTHER_FUTURE_FILTER_ACTION";
  payload: any;
}

const initialFilterState: FilterState = {
  taxYear: "",
  location: [],
};

const filterReducer = (
  state: FilterState,
  action: FilterAction,
): FilterState => {
  if (action.type === "SET_TAX_YEAR") {
    return { ...state, taxYear: action.payload };
  }

  if (action.type === "SET_LOCATION") {
    return { ...state, location: action.payload };
  }

  if (action.type == "CLEAR_ALL") {
    return initialFilterState;
  }

  return state;
};

export const useFilter = (shouldDefaultCurrentYear: boolean) => {
  const [state, dispatch] = useReducer(filterReducer, initialFilterState);

  const customDispatch = (action: FilterAction) => {
    if (action.type === "CLEAR_ALL" && shouldDefaultCurrentYear) {
      dispatch({
        type: "SET_TAX_YEAR",
        payload: new Date().getFullYear().toString(),
      });
    } else {
      dispatch(action);
    }
  };

  return {
    state,
    dispatch: customDispatch,
  };
};
