import { FC } from "react";
import styles from "./NavigationTabs.module.scss";
import { NavLink } from "react-router-dom";
import { useOktaAuth } from "@okta/okta-react";
import { isAdmin } from "../../../../utils/helpers/AuthHelper";

const NavigationTabs: FC = () => {
  const {authState} = useOktaAuth();
  const isAuthAndAdmin: boolean = authState?.isAuthenticated && isAdmin();

  return (
    <nav className={styles.navLinkContainer}>
      <NavLink
        className={styles.navLinkItems}
        to="/"
        exact
        activeClassName={styles.active}
      >
        Home
      </NavLink>
      <NavLink
        className={styles.navLinkItems}
        to="/history"
        activeClassName={styles.active}
      >
        History
      </NavLink>
      <NavLink
        className={styles.navLinkItems}
        to="/delegate"
        activeClassName={styles.active}
      >
        Options
      </NavLink>
      { isAuthAndAdmin &&
      <NavLink
        className={styles.navLinkItems}
        to="/admin"
        activeClassName={styles.active}
      >
        Admin
      </NavLink>
      }
    </nav>
  );
};
export default NavigationTabs;
