import widgetStyles from "../components/WindowWidget/WindowWidget.module.scss";

export const validationWindowWidgetOutput = () => {
  if (isValidationWindowOpen()) {
    return {
      statusCardText: "OPEN",
      statusTextStyle: widgetStyles.windowOpen,
    };
  }
  return {
    statusCardText: "CLOSED",
    statusTextStyle: widgetStyles.windowClosed,
  };
};

export const isValidationWindowOpen = () => {
  const day = parseInt(
    new Date()
      .toLocaleDateString("en-US", { timeZone: "America/New_York" })
      .split("/")[1],
  );
  return day >= 10 && day <= 21; 
};