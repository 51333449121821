import { useQuery } from "@tanstack/react-query";
import { businessTravelService } from "../../../utils/API";
import { Person } from "@cfacorp/business-travel-api-client";

const useGetPeople = () => {
  const fetchPeople = async (): Promise<Person[]> => {
    return await businessTravelService.getPeople();
  };

  return useQuery({
    queryKey: ["People"],
    queryFn: fetchPeople,
  });
};

export { useGetPeople };
