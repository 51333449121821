import { businessTravelService } from "../../../utils/API";
import { BannerMessage } from "@cfacorp/business-travel-api-client";
import { useQuery } from "@tanstack/react-query";

const useGetBannerMessages = () => {
  const fetchBannerMessages = async (): Promise<BannerMessage[]> => {
    return businessTravelService.getActiveBannerMessages();
  };

  return useQuery({
    queryKey: ["BannerMessages"],
    queryFn: fetchBannerMessages,
  });
};

export { useGetBannerMessages };
