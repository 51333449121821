import { useMutation } from "@tanstack/react-query";
import { businessTravelService } from "../../../utils/API";
import { Trip } from "../../../pages/TripDetails/utils/tripDaySchemas";

const usePostTripDaysMutation = (createManualTrip: boolean) => {
  const updateTrips = async (tripDays: Trip) => {
    if (!tripDays?.tripDays) return;
    const body = tripDays.tripDays.map((tripDay) => {
      const { workType, location, day, ...rest } = tripDay;
      return {
        ...rest,
        day: day?.toUpperCase(),
        dayType: workType.dayType,
        dayReason: workType.dayReason,
        ...location,
      };
    });
    await businessTravelService.updateTripDays({
      requestBody: { tripDays: body },
      createManualTrip,
    });
  };

  return useMutation({
    mutationFn: updateTrips,
  });
};

export { usePostTripDaysMutation };
