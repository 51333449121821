import { TripDay } from "@cfacorp/business-travel-api-client";
import tripCardstyles from "../../pages/Home/components/TripCard/TripCard.module.scss";
import singleLocationIcon from "../../assets/icons/LocationIcon.png";
import twoLocationIcon from "../../assets/icons/MultiLocationIcon.png";
import DelegateUser from "../../interfaces/DelegateUser";
import { DayField } from "../../pages/TripDetails/utils/tripDaySchemas";

export const locationStringOutput = (
  tripDays: TripDay[],
): { locationsText: string; needsToolTip: boolean; icon: string } => {
  const locations = getUniqueLocations(tripDays);
  if (locations.length === 2) {
    return {
      locationsText: locations[0] + " & " + locations[1],
      needsToolTip: false,
      icon: twoLocationIcon,
    };
  }
  if (locations.length === 3) {
    return {
      locationsText: locations[0] + ", " + locations[1] + " & 1 other",
      needsToolTip: true,
      icon: twoLocationIcon,
    };
  }
  if (locations.length > 3) {
    return {
      locationsText:
        locations[0] +
        ", " +
        locations[1] +
        " & " +
        (locations.length - 2) +
        " others",
      needsToolTip: true,
      icon: twoLocationIcon,
    };
  }

  return {
    locationsText: locations[0],
    needsToolTip: false,
    icon: singleLocationIcon,
  };
};

export const getUniqueLocations = (tripDays: TripDay[]) =>
  [
    ...new Set(
      tripDays
        .map((tripDay) =>
          tripDay.countryCode === "US" || tripDay.countryCode == "CA"
            ? tripDay.state
            : tripDay.country,
        )
        .filter((location) => location !== undefined),
    ),
  ] as string[];

export const tripStatusOutput = (tripStatus?: TripDay.tripStatus) => {
  if (tripStatus === "OPEN") {
    return {
      statusCardText: "Unvalidated",
      statusTextColor: tripCardstyles.unvalidated,
    };
  }
  if (tripStatus === "PROCESSED") {
    return {
      statusCardText: "Processed",
      statusTextColor: tripCardstyles.processed,
    };
  }
  return {
    statusCardText: "Validated",
    statusTextColor: tripCardstyles.validated,
  };
};

export const formatDate = (dateString: string, showYear: boolean = true) => {
  if (!dateString) {
    return "";
  }
  const [year, month, day] = dateString.split("-");
  const date = new Date(Number(year), Number(month) - 1, Number(day));

  if (showYear) {
    const options = {
      month: "long",
      day: "numeric",
      year: "numeric",
    } as const;
    return date.toLocaleDateString("en-US", options);
  } else {
    const options = {
      month: "long",
      day: "numeric",
    } as const;
    return date.toLocaleDateString("en-US", options);
  }
};

export const tripDateOutput = (tripDays: TripDay[] | DayField[]) => {
  const dates = tripDays.map((tripDay) => tripDay.date);

  const startDate = dates.reduce(
    (min, date) => (date < min ? date : min),
    dates[0],
  );
  const endDate = dates.reduce(
    (max, date) => (date > max ? date : max),
    dates[0],
  );

  const formattedStartDate = formatDate(startDate);
  const formattedEndDate = formatDate(endDate);
  const formattedRange = `${formattedStartDate} - ${formattedEndDate}`;

  return { formattedStartDate, formattedEndDate, formattedRange };
};

export const tripStartAndEnd = (
  tripDays: TripDay[] | DayField[] | undefined,
) => {
  if (!tripDays || tripDays.length === 0) {
    return { startDate: null, endDate: null };
  }
  const { formattedStartDate, formattedEndDate } = tripDateOutput(tripDays);
  const startDate = new Date(formattedStartDate);
  const endDate = new Date(formattedEndDate);
  return { startDate, endDate };
};

export const tripListsToDisplay = (
  trips: TripDay[],
  selectedUser: DelegateUser | undefined,
) => {
  if (!selectedUser || trips.length === 0)
    return { validatedTripsToDisplay: [], unvalidatedTripsToDisplay: [] };

  const validatedTrips = trips.filter(
    (day) =>
      day.tripStatus === "VERIFIED" && day.personId === selectedUser.personId,
  );
  const unvalidatedTrips = trips.filter(
    (day) =>
      day.tripStatus === "OPEN" && day.personId === selectedUser.personId,
  );

  const validatedTripIds = [
    ...new Set(validatedTrips.map((day) => day.tripId)),
  ];
  const unvalidatedTripIds = [
    ...new Set(unvalidatedTrips.map((day) => day.tripId)),
  ];

  const validatedTripsToDisplay = validatedTripIds.map((tripId) =>
    validatedTrips.filter((day) => day.tripId === tripId),
  );

  const unvalidatedTripsToDisplay = unvalidatedTripIds.map((tripId) =>
    unvalidatedTrips.filter((day) => day.tripId === tripId),
  );

  return { validatedTripsToDisplay, unvalidatedTripsToDisplay };
};

export const processedTripsToDisplay = (
  tripDays: TripDay[],
  selectedUser: DelegateUser | undefined,
) => {
  if (!selectedUser || tripDays.length === 0) return [];

  const selectedUsersTrips = tripDays.filter(
    (day) => day.personId === selectedUser.personId,
  );

  const tripIds = [...new Set(selectedUsersTrips.map((day) => day.tripId))];

  return tripIds.map((tripId) =>
    selectedUsersTrips.filter((day) => day.tripId === tripId),
  );
};
