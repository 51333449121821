import {
  useDeleteBannerMessage,
  useUpdateBannerMessage,
} from "../../../../../hooks/api/admin";
import {
  Dropdown,
  Modal,
  ModalBody,
  ModalFooter,
  ModalHeader,
  TextFieldType,
  TextField,
  Typography,
  Switch,
  DatePicker,
  Button,
} from "@cfa/react-components";
import { Grid } from "@mui/material";
import { BannerMessage } from "@cfacorp/business-travel-api-client";
import "./ModifyBannerDialog.scss";
import I18n from "../../../../../i18n/utils";
import React, { useEffect, useState } from "react";

// if we don't want an expiration date, set it far into the future...
export const NO_EXPIRATION_DATE = "2999-12-31";

export interface ModifyBannerDialogProp {
  open: boolean;
  setOpen: (open: boolean) => void;
  mode: "create" | "modify" | "delete";
  message: BannerMessage;
  setMessage: (message: any) => void;
}

const ModifyBannerDialog: React.FC<ModifyBannerDialogProp> = ({
  open,
  setOpen,
  mode,
  message,
  setMessage,
}): JSX.Element => {
  const [errorMessage, setErrorMessage] = useState<string>();
  const { mutate: saveBannerMessage } = useUpdateBannerMessage();
  const { mutate: deleteBannerMessage } = useDeleteBannerMessage();
  const [expirationDate, setExpirationDate] = useState<Date>();
  const hasExpirationDate =
    expirationDate?.toDateString() !==
    new Date(NO_EXPIRATION_DATE).toDateString();
  const handleSave = () => {
    if (mode === "delete") {
      deleteBannerMessage(message.messageId || "", {
        onSuccess: () => setOpen(false),
        onError: () => setErrorMessage(I18n.t("DELETE_BANNER_ERROR")),
      });
    } else {
      const m: BannerMessage = {
        ...message,
        expirationDate:
          expirationDate?.toISOString().split("T")[0] || NO_EXPIRATION_DATE,
      };

      saveBannerMessage(
        { bannerMessage: m, isNew: mode === "create" },
        {
          onSuccess: () => setOpen(false),
          onError: () => setErrorMessage(I18n.t("SAVE_BANNER_ERROR")),
        },
      );
    }
  };

  useEffect(() => {
    setErrorMessage(undefined);
    setExpirationDate(new Date(message.expirationDate));
  }, [open, message.expirationDate]);

  return (
    <Modal
      show={open}
      onClose={() => {
        setOpen(false);
      }}
      aria-labelledby="alert-dialog-title"
      aria-describedby="alert-dialog-description"
      size="lg"
    >
      <ModalHeader id="alert-dialog-title" as="div">
        <Typography variant="h1">
          {mode === "create"
            ? "New Banner Message"
            : mode === "modify"
              ? "Modify Banner Message"
              : "Delete Banner Message"}
        </Typography>
        {mode === "delete" && (
          <Typography>{I18n.t("DELETE_MODAL_HELPER")}</Typography>
        )}
        {errorMessage && (
          <Typography color="primary" variant="h4">
            {errorMessage}
          </Typography>
        )}
      </ModalHeader>
      <ModalBody>
        <Grid container direction="column">
          <Grid item xs={12}>
            <Dropdown
              readOnly={mode === "delete"}
              label="Select Banner Severity"
              value={message.severity}
              onChange={(option) =>
                setMessage((m: BannerMessage) => ({ ...m, severity: option }))
              }
              options={Object.values(BannerMessage.severity)}
            />
          </Grid>
          <Grid item xs={12}>
            <TextField
              readOnly={mode === "delete"}
              className="banner-textfield"
              label="Banner Message Text"
              multiline
              margin
              fullWidth
              placeholder={I18n.t("MODAL_DEFAULT_TEXT")}
              textAlign="start"
              value={message.message}
              rows={6}
              onChange={(e: React.ChangeEvent<TextFieldType>) => {
                e.persist();
                setMessage((m: BannerMessage) => ({
                  ...m,
                  message: e.target.value,
                }));
              }}
            />
          </Grid>
          <Grid item>
            <Switch
              disabled={mode === "delete"}
              label="Expiration Date"
              checked={hasExpirationDate}
              onChange={(e) => {
                if (e.target.checked) {
                  setExpirationDate(new Date());
                } else setExpirationDate(new Date(NO_EXPIRATION_DATE));
              }}
              nonce={undefined}
            />

            {hasExpirationDate && (
              <DatePicker
                readOnly={mode === "delete"}
                margin
                value={expirationDate}
                onChange={(date: Date | null) => {
                  if (date) setExpirationDate(date);
                }}
              />
            )}
          </Grid>
        </Grid>
      </ModalBody>
      <ModalFooter>
        <Button
          onClick={() => setOpen(false)}
          className="acceptButton"
          data-test="disagree"
        >
          {I18n.t("APP_DAY_LIST_CANCEL_BUTTON_LABEL")}
        </Button>
        <Button className="acceptButton" onClick={handleSave} data-test="agree">
          {mode === "create"
            ? I18n.t("CREATE_BUTTON")
            : mode === "delete"
              ? I18n.t("DELETE_BUTTON")
              : I18n.t("SAVE_BUTTON")}
        </Button>
      </ModalFooter>
    </Modal>
  );
};

export default ModifyBannerDialog;
