import { businessTravelService } from "../../../utils/API";
import { BannerMessage } from "@cfacorp/business-travel-api-client";
import { useMutation, useQueryClient } from "@tanstack/react-query";

const useUpdateBannerMessage = () => {
  const queryClient = useQueryClient();
  const updateBannerMessage = async ({
    bannerMessage,
    isNew,
  }: {
    bannerMessage: BannerMessage;
    isNew: boolean;
  }) => {
    await businessTravelService.saveBannerMessage({
      requestBody: bannerMessage,
      newMessage: isNew,
    });
  };

  return useMutation({
    mutationFn: updateBannerMessage,
    onSuccess: () =>
      queryClient.invalidateQueries({ queryKey: ["BannerMessages"] }),
  });
};

export { useUpdateBannerMessage };
