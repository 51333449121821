import { LoadingIndicator } from "@cfa/react-components";
import styles from "./Loading.module.scss";

const Loading: React.FC = () => {
  return (
    <LoadingIndicator
      data-testid={"inline-loading-indicator"}
      className={styles.loading}
      variant={"inline"}
    />
  );
};

export default Loading;
