import { Dropdown, Typography } from "@cfa/react-components";
import { useDelegateContext } from "../../context/DelegateContext";
import { retrieveAuthUserCfaId } from "../../utils/helpers/AuthHelper";
import { getOktaAccessToken } from "../../utils/LocalStorage";

const DelegateSelectDropDown: React.FC = () => {
  const { delegateUsers, selectedUser, setSelectedUser } = useDelegateContext();
  return delegateUsers && delegateUsers.length > 1 ? (
    <Dropdown
      data-testid={"delegate-select-dropdown"}
      getOptionText={(option) => option?.firstName || ""}
      getOptionId={(option) => option?.personId || ""}
      options={delegateUsers}
      value={selectedUser}
      renderOption={(user) => 
      <Typography>
        {user?.firstName}
        {retrieveAuthUserCfaId(getOktaAccessToken()) === user?.cfaId ? " (Me)" : ""}
      </Typography>}
      onChange={(e) => e && setSelectedUser(e)}
      compact={true}
      label="Viewing as"
    />
  ) : (
    <></>
  );
};
export default DelegateSelectDropDown;
