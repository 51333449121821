import { FC, useState } from "react";
import styles from "./ProcessedTripsWidget.module.scss";
import { Card, Typography } from "@cfa/react-components";
import { useDelegateContext } from "../../../../../context/DelegateContext";
import { processedTripsToDisplay } from "../../../../../utils/helpers/tripCardHelper";
import ProcessedTripCard from "../ProcessedTripCard/ProcessedTripCard";
import { IconChevronDown } from "@tabler/icons-react";
import Loading from "../../../../../components/Loading/Loading";
import { TripDay } from "@cfacorp/business-travel-api-client";

interface ProcessedTripsWidgetProps {
  tripDays: TripDay[];
  isLoading: boolean;
}

const ProcessedTripsWidget: FC<ProcessedTripsWidgetProps> = ({
  tripDays,
  isLoading,
}) => {
  const {
    isDelegate,
    selectedUser,
    isLoading: isDelegateLoading,
  } = useDelegateContext();
  const [isSortDesc, setIsSortDesc] = useState(true);
  const handleSort = () => {
    setIsSortDesc((prev) => !prev);
  };

  const sortedTripDays = tripDays.sort((a, b) =>
    isSortDesc ? b.date.localeCompare(a.date) : a.date.localeCompare(b.date),
  );

  return (
    <Card elevation={4} className={styles.container}>
      {isLoading || isDelegateLoading ? (
        <Loading />
      ) : (
        <>
          <div className={styles.header} data-testid={"history-dynamic-header"}>
            <Typography className={styles.headerText} variant={"h2"}>
              {isDelegate ? (
                <>
                  <Typography variant={"h2"} className={styles.delegateHeader}>
                    {selectedUser?.firstName}'s
                  </Typography>{" "}
                  Trips
                </>
              ) : (
                "My Trips"
              )}
            </Typography>
          </div>
          <div className={styles.sortButton} onClick={handleSort}>
            <Typography fontWeight={"bold"}>Sort</Typography>
            {isSortDesc ? (
              <IconChevronDown className={styles.icon} stroke={2} />
            ) : (
              <IconChevronDown
                className={`${styles.iconUp} ${styles.icon}`}
                stroke={2}
              />
            )}
          </div>
          {processedTripsToDisplay(sortedTripDays, selectedUser).map((trip) => {
            return <ProcessedTripCard tripDays={trip} />;
          })}
          {processedTripsToDisplay(sortedTripDays, selectedUser).length ===
            0 && (
            <Typography className={styles.noResults}>No results</Typography>
          )}
        </>
      )}
    </Card>
  );
};

export default ProcessedTripsWidget;
