import { useMutation, useQueryClient } from "@tanstack/react-query";
import { businessTravelService } from "../../../utils/API";

const useDeleteBannerMessage = () => {
  const queryClient = useQueryClient();
  const deleteBannerMessage = async (bannerMessageId: string) => {
    await businessTravelService.deleteBannerMessage({
      messageId: bannerMessageId,
    });
  };

  return useMutation({
    mutationFn: deleteBannerMessage,
    onSuccess: () =>
      queryClient.invalidateQueries({ queryKey: ["BannerMessages"] }),
  });
};

export { useDeleteBannerMessage };
